var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container menu" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "88px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "40px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 13 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建菜单"],
                                expression: "['新建菜单']",
                              },
                            ],
                            staticClass: "btnse",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(0, "father")
                              },
                            },
                          },
                          [_vm._v("新建菜单")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%", "margin-bottom": "20px" },
                attrs: {
                  data: _vm.tableData,
                  "row-key": "id",
                  border: "",
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "meta.title", label: "部门名称" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "380" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["新增子菜单"],
                                    expression: "['新增子菜单']",
                                  },
                                ],
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row.id, "son")
                                  },
                                },
                              },
                              [_vm._v("新增子菜单")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["编辑"],
                                    expression: "['编辑']",
                                  },
                                ],
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row.id, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["删除"],
                                    expression: "['删除']",
                                  },
                                ],
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3156454132
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialog",
                attrs: { title: "新增", visible: _vm.dialogFormVisible },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                  close: _vm.resetTemps,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    staticStyle: { width: "400px", "margin-left": "50px" },
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.temp,
                      "label-position": "right",
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "sort" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入sort(菜单排序)" },
                          model: {
                            value: _vm.temp.sort,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "sort", $$v)
                            },
                            expression: "temp.sort",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "title" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入菜单名称" },
                          model: {
                            value: _vm.temp.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "title", $$v)
                            },
                            expression: "temp.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "path" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入path" },
                          model: {
                            value: _vm.temp.path,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "path", $$v)
                            },
                            expression: "temp.path",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "component" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入component(注册路径)" },
                          model: {
                            value: _vm.temp.component,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "component", $$v)
                            },
                            expression: "temp.component",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "api" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入api(注册路径)" },
                          model: {
                            value: _vm.temp.api,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "api", $$v)
                            },
                            expression: "temp.api",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "is_menu" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入级别 (0操作,1菜单)" },
                          model: {
                            value: _vm.temp.is_menu,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "is_menu", $$v)
                            },
                            expression: "temp.is_menu",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("\n                    取消\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.createData },
                      },
                      [_vm._v("\n                    确认\n                ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }