import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.sort";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import { AuthRuleRuleList, Authsave, Authread, Authupdate, Authdelete } from '@/api/updateUserInfo/users';
import NProgress from 'nprogress'; // progress bar
import Head from '@/components/head/index';
import 'nprogress/nprogress.css'; // progress bar style
export default {
  name: 'nxframe',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '菜单管理',
      value1: '',
      value: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      urlPath: this.getUrlPath(),
      // iframe src 路径，
      dialogFormVisible: false,
      rules: {
        name: [{
          required: true,
          message: '请输入必填项',
          trigger: 'change'
        }],
        pid: [{
          required: true,
          message: '请输入必填项',
          trigger: 'blur'
        }]
      },
      temp: {
        pid: '',
        // 默认是0,为顶级分类.如不选择则为顶级分类
        sort: 1,
        //菜单排序
        title: '',
        // 栏目名称
        path: '',
        // 定义
        component: '',
        // 注册路径
        api: '',
        is_menu: 1 //
      },
      newly: '',
      tableData: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList();
    } else {}
  },
  components: {
    Head: Head
  },
  methods: {
    getList: function getList() {
      var _this = this;
      //栏目列表
      AuthRuleRuleList().then(function (respomse) {
        _this.tableData = respomse.data;
      });
    },
    resetTemps: function resetTemps() {
      //清空公告弹窗
      this.temp = {
        pid: '',
        // 默认是0,为顶级分类.如不选择则为顶级分类
        sort: 1,
        //菜单排序
        title: '',
        // 栏目名称
        path: '',
        // 定义
        component: '',
        // 注册路径
        api: '',
        is_menu: 1 //
      };
    },
    handleEdit: function handleEdit(id, rt) {
      var _this2 = this;
      //  新增/新增子栏目/编辑弹窗
      this.dialogFormVisible = true;
      this.temp.pid = id;
      this.newly = rt;
      if (rt == 'edit') {
        //获取栏目信息
        Authread({
          id: id
        }).then(function (respomse) {
          var data = respomse.data;
          _this2.temp.pid = data.id;
          _this2.temp.sort = data.sort;
          _this2.temp.title = data.title;
          _this2.temp.path = data.path;
          _this2.temp.component = data.component;
          _this2.temp.api = data.api;
          _this2.temp.is_menu = data.is_menu;
        });
      }
    },
    createData: function createData() {
      var _this3 = this;
      this.dialogFormVisible = false;
      var data = {
        title: this.temp.title,
        component: this.temp.component,
        path: this.temp.path,
        api: this.temp.api,
        is_menu: this.temp.is_menu,
        pid: this.temp.pid,
        sort: this.temp.sort
      };
      if (this.newly == 'son') {
        Authsave(data).then(function (respomse) {
          //新增子栏目
          _this3.getList();
        });
      } else if (this.newly == 'father') {
        //新增栏目
        Authsave(data).then(function (respomse) {
          _this3.getList();
          _this3.resetTemps();
          _this3.$notify({
            title: '成功',
            message: '新建菜单成功',
            type: 'success',
            duration: 2000
          });
        });
      } else {
        //编辑栏目
        Authupdate({
          title: this.temp.title,
          component: this.temp.component,
          path: this.temp.path,
          api: this.temp.api,
          is_menu: this.temp.is_menu,
          id: this.temp.pid,
          sort: this.temp.sort
        }).then(function (respomse) {
          _this3.getList();
          _this3.resetTemps();
          _this3.$notify({
            title: '成功',
            message: '编辑菜单成功',
            type: 'success',
            duration: 2000
          });
        });
      }
    },
    handleDelete: function handleDelete(id, row) {
      var _this4 = this;
      //删除栏目
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Authdelete({
          id: String(id)
        }).then(function (respomse) {
          _this4.getList();
          _this4.$notify({
            title: '成功',
            message: '删除菜单成功',
            type: 'success',
            duration: 2000
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getUrlPath: function getUrlPath() {
      // 获取 iframe src 路径
      var url = window.location.href;
      url = url.replace('/myiframe', '');
      return url;
    }
  }
};